
import React from "react";
import Index from "../../Index";

export default function AuthFooter() {
  return (
    <div>
      <Index.Box className="auth-footer-main">
        <Index.Box className="auth-footer-social-main">
          <Index.List className="auth-social-ul">
            <a
              href="https://www.instagram.com/ncx.cx/"
              className="auth-social-link"
              target={"_blank"}
            >
              <Index.ListItem className="auth-social-li">
                <img
                  src={Index.Svg.instagram}
                  className="auth-socual-icon"
                  alt="social media"
                ></img>
              </Index.ListItem>
            </a>
            <a
              href="https://t.me/NCX_Official"
              className="auth-social-link"
              target={"_blank"}
            >
              <Index.ListItem className="auth-social-li">
                <img
                  src={Index.Svg.share}
                  className="auth-socual-icon"
                  alt="social media"
                ></img>
              </Index.ListItem>
            </a>
            <a
              href="https://twitter.com/NCX_CX"
              className="auth-social-link"
              target={"_blank"}
            >
              <Index.ListItem className="auth-social-li">
                <img
                  src={Index.Svg.twitter}
                  className="auth-socual-icon"
                  alt="social media"
                ></img>
              </Index.ListItem>
            </a>
          </Index.List>
        </Index.Box>
        <Index.Box className="auth-footer-flex display-flex">
          <a href="https://ncx.cx/privacy-policy"
            className="text-decoration auth-footer-para "
          >
            Privacy Policy
          </a>
          <a href="https://ncx.cx/user-agreement"
            className="auth-footer-para text-decoration"
          >
            Terms & Conditions
          </a>
        </Index.Box>
        <Index.Typography
          className="auth-footer-terms-para"
          variant="p"
          component="p"
        >
          DEMO Digital Exchange L.L.C., Company Reg. Number - 2477 LLC 2023 ©
          2022-2023 All rights reserved.
        </Index.Typography>
      </Index.Box>
    </div>
  );
}
