import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";

export default function BlockedUsers() {
  const token = useSelector((store) => store.admin.token);
  const adminType = useSelector((store) => store.admin.adminType);
  const [users, setUsers] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      field: "ncxUserId",
      headerName: "Demo UserId",
      width: "200",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "userName",
      headerName: "Name",
      width: "250",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: "250",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "Status",
      headerName: "Status",
      width: "150",
      disableColumnMenu: true,
      sortable: false,
      renderCell: () => {
        return <>Blocked</>;
      },
    },
    adminType == 0 && {
      field: "actions",
      headerName: "Action",
      width: "100",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Index.Button
            onClick={() => {
              userUnblock(params?.row?._id);
            }}
          >
            Unblock
          </Index.Button>
        );
      },
    },
  ];
  const getBlockedUserList = async () => {
    await DataService.get(API.Admin.BLOCKED_USER_LIST, {
      headers: {
        auth: token,
      },
    }).then((response) => {
      setUsers(response?.data?.data);
    });
  };
  const userUnblock = async (params) => {
    const formData = new URLSearchParams();
    formData.append("id", params);
    formData.append("isAccountBlocked", false);
    await DataService.post(API.Admin.UNBLOCK_USER, formData, {
      headers: {
        auth: token,
      },
    }).then(() => {
      getBlockedUserList();
    }).catch((error)=>{
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/admin/login")
      }
    });
  };
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/admin/login"></Navigate>;
    }
  }, []);
  useEffect(() => {
    getBlockedUserList();
  }, []);
  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Blocked Users
                </Index.Typography>
                <ToastContainer />
              </Index.Box>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ height: 530, width: "100%" }}>
                  <Index.DataGrid
                    className="user-data-head"
                    rows={users}
                    columns={columns}
                    pageSize={10}
                    getRowId={(row) => row?.ncxUserId}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={isFetching}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
