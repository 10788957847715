import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
  getRoundData,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import dayjs from "dayjs";
import PrivateSaleWalletCard from "../../../../component/common/PrivateSaleWalletCard";

// private sale wallet modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
};

export default function PrivateSaleWallet() {
  const token = useSelector((store) => store.admin.userToken);
  const balanceData = useSelector((store) => store.admin.userBalanceData);
  const userData = useSelector((store) => store.admin.userData);
  // private sale wallet modal
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [roundData, setRoundData] = useState(balanceData);
  const [amount, setAmount] = useState("");
  const [roundId, setRoundId] = useState("");
  const [OTP, setOTP] = useState("");
  const [availabaleToWithdraw, setAvailabaleToWithdraw] = useState(0);
  const [seconds, setSeconds] = useState(60);
  const [roundList, setRoundList] = useState([]);
  const reverseArray = (arr) => {
    var reverseArrayData = Object.assign([], arr);
    return reverseArrayData;
  };

  const handleOpenWallet = (roundId) => {
    setOpen(true);
    setRoundId(roundId);
  };
  const handleCloseWallet = () => setOpen(false);

  const sendOTP = async () => {
    let Api_endPoint =
      seconds !== 0 ? API.User.sendRequestOTP : API.User.ResendOTP;
    await DataService.get(Api_endPoint, {
      headers: { auth: token },
    })
      .then((data) => {
        if (data.status == 201 || data.status == 200) {
          toast.success("Otp sent to your mail");
        }
        setFlag(!flag);
        setSeconds(60);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };

  const getSaleRoundList = async () => {
    let rounds = [];
    await DataService.get(API.User.getPrivateSale, {
      headers: {
        auth: token,
      },
    })
      .then(async (response) => {
        dispatch(getRoundData(response?.data?.data));
        await DataService.get(API.User.totalPurchased, {
          headers: {
            auth: token,
          },
        })
          .then(async (purchaseresponse) => {
            await DataService.get(API.User.privatesaleWallet, {
              headers: {
                auth: token,
              },
            })
              .then(async (currentRoundData) => {
                response.data.data.map((roundData) => {
                  const purchaseRound = purchaseresponse.data.data.find(
                    (purchasedRoundData) => {
                      return purchasedRoundData?.roundId === roundData?._id;
                    }
                  );
                  const currentRound = currentRoundData.data.data.find(
                    (currentRoundData) => {
                      return currentRoundData?.roundId === roundData?._id;
                    }
                  );

                  const objData = {
                    roundId: roundData?._id,
                    phaseName: roundData?.phaseName,
                    startDate: roundData?.startDate,
                    endDate: roundData?.endDate,
                    vestingPeriod: roundData?.vestingPeriod,
                    monthlyRelease: roundData?.monthlyRelease,
                    locked: purchaseRound?.locked ?? 0,
                    withdrawn: purchaseRound?.withdrawn ?? 0,
                    monthlyReleaseAmount: purchaseRound?.monthlyReleaseAmount
                      ? purchaseRound?.monthlyReleaseAmount
                      : currentRound?.monthlyReleaseAmount
                        ? currentRound?.monthlyReleaseAmount
                        : 0,
                    purchasedTokens: purchaseRound?.purchasedTokens
                      ? purchaseRound?.purchasedTokens
                      : currentRound?.tokenPurchase
                        ? currentRound?.tokenPurchase
                        : 0,
                    percentRemainingWithdraw:
                      purchaseRound?.percentRemainingWithdraw ?? 0,
                    vestingCompleteDate:
                      purchaseRound?.vestingCompleteDate ?? undefined,
                    totalAvailableToWithdraw:
                      purchaseRound?.totalAvailableToWithdraw,
                  };
                  rounds.push(objData);
                });
              })
              .catch((error) => { });
          })
          .catch((error) => { });
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });

    setRoundList(rounds);
  };

  const getSaleRoundData = async () => { };

  useEffect(() => {
    if (flag) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          clearInterval(interval);
          setFlag(false);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, flag]);
  useEffect(() => {
    getSaleRoundList();
    getSaleRoundData();
  }, []);

  const handleWalletRequest = async (e) => {
    e.preventDefault();
    if (amount > availabaleToWithdraw) {
      setError("Withdrawal amount cannot be greater than available balance");
    } else if (amount === "") {
      setError("Please provide withdraw ammount");
    }
    if (OTP === "") {
      setOtpError("OTP is required");
    }
    const formData = new URLSearchParams();
    formData.append("otp", OTP);
    formData.append("requestToken", amount);
    formData.append("roundId", roundId);
    await DataService.post(API.User.requestWithdrawToken, formData, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          toast.success("Withdraw Successfully.");
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  const onChangeAmount = (e) => {
    const amountValue = e.target.value;
    if (amountValue > availabaleToWithdraw) {
      setError("Withdrawal amount cannot be greater than available balance");
    } else {
      setError("");
    }
    setAmount(amountValue);
  };
  const onOtpChange = (e) => {
    const otpvalue = e.target.value.slice(0, 6);
    if (otpvalue === "") {
      setOtpError("OTP is required");
    } else {
      setOtpError("");
    }
    setOTP(otpvalue);
  };
  return (
    <>
      <Index.Header />
      <Index.Box className="page-wrapper">
        <ToastContainer />
        <Index.Box className="container">
          <Index.Box className="page-inner-main">
            <Index.Box className="page-left-main">
              <Index.UserSidebar />
            </Index.Box>
            <Index.Box className="page-right-main private-sale-wallet-right-main">
              <Index.Box className="private-sale-wallet-main">
                <Index.Box className="private-card-row">
                  {/* mobile view */}

                  <Index.Box className="private-card  private-wallet-mobile-card">
                    <Index.Box className="private-card-flex">
                      <Index.Box className="dash-icon-main">
                        <img
                          src={Index.Svg.ncxlogoicon}
                          className="private-icons"
                        />
                      </Index.Box>
                      <Index.Box className="private-icon-text-main">
                        <Index.Typography
                          className="private-icon-text"
                          component="h3"
                          variant="h3"
                        >
                          Total Balance
                        </Index.Typography>
                        <Index.Typography
                          className="private-icon-price"
                          component="h3"
                          variant="h3"
                        >
                          {roundData?.reward
                            ? roundData?.reward
                              ?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                              })
                              .split("₹")[1]
                              .split(".")[0]
                            : "0.00"}
                          <span className="private-span-text">NCXT</span>
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="private-card-wallet-flex">
                      <Index.Box className="private-card-wallet-inner">
                        <Index.Box className="private-card-content">
                          <Index.Box className="private-card-inner-content">
                            <Index.Typography
                              className="private-price-text"
                              component="h3"
                              variant="h3"
                            >
                              {roundData?.purchaseToken !== 0
                                ? roundData?.purchaseToken
                                  ?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                  })
                                  .split("₹")[1]
                                  .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="private-price-label"
                              component="h3"
                              variant="h3"
                            >
                              Purchased Token
                            </Index.Typography>
                          </Index.Box>
                          <Link
                            to={"/transactions"}
                            className="text-decoration"
                          >
                            <Index.WhiteBorderButton
                              className="white-border-btn dash-btn"
                              btnLabel="HISTORY"
                            />
                          </Link>
                        </Index.Box>
                        <Index.Box className="private-card-content">
                          <Index.Box className="private-card-inner-content">
                            <Index.Typography
                              className="private-price-text"
                              component="h3"
                              variant="h3"
                            >
                              {roundData.refferalReward && roundData.welcomeReward
                                ? (
                                  roundData?.refferalReward +
                                  roundData?.welcomeReward
                                )
                                  ?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                  })
                                  .split("₹")[1]
                                  .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="private-price-label"
                              component="h3"
                              variant="h3"
                            >
                              Airdrop & Referrals
                            </Index.Typography>
                          </Index.Box>
                          <Link
                            to={"/refferals"}
                            className="text-decoration"
                          >
                            <Index.WhiteBorderButton
                              className="white-border-btn dash-btn"
                              btnLabel="HISTORY"
                            />
                          </Link>
                        </Index.Box>
                        <Index.Box className="private-card-content">
                          <Index.Box className="private-card-inner-content">
                            <Index.Typography
                              className="private-price-text"
                              component="h3"
                              variant="h3"
                            >
                              {userData?.availabaleToWithdraw !== 0
                                ? userData?.availabaleToWithdraw
                                  ?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                  })
                                  .split("₹")[1]
                                  .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="private-price-label"
                              component="h3"
                              variant="h3"
                            >
                              Monthly Release
                            </Index.Typography>
                          </Index.Box>
                          <Index.WhiteBorderButton
                            className="white-border-btn dash-btn"
                            btnLabel="HISTORY"
                            onClick={handleOpenWallet}
                          />
                        </Index.Box>
                        <Index.Box className="private-card-content">
                          <Index.Box className="private-card-inner-content">
                            <Index.Typography
                              className="private-price-text"
                              component="h3"
                              variant="h3"
                            >
                              {userData?.withdraw !== 0
                                ? userData?.withdraw
                                  ?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                  })
                                  .split("₹")[1]
                                  .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="private-price-label"
                              component="h3"
                              variant="h3"
                            >
                              Withdrawn
                            </Index.Typography>
                          </Index.Box>
                          <Link
                            to={"/transactions"}
                            className="text-decoration"
                          >
                            <Index.WhiteBorderButton
                              className="white-border-btn dash-btn"
                              btnLabel="HISTORY"
                            />
                          </Link>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  {/* deskstop view */}
                  <PrivateSaleWalletCard
                    className="private-card private-card-1"
                    imgclass="dash-icon-main"
                    img={
                      <img
                        src={Index.Svg.ncxlogoicon}
                        className="private-icons"
                      />
                    }
                    Label={"Total Balance"}
                    data={
                      roundData?.reward
                        ? roundData?.reward
                          ?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "INR",
                          })
                          .split("₹")[1]
                          .split(".")[0]
                        : "0.00"
                    }
                    endClass={
                      "private-card-content-end private-card-1-content-end"
                    }
                    path={"/transactions"}
                  />
                  <PrivateSaleWalletCard
                    className="private-card private-card-2"
                    Label={"Purchased Token"}
                    data={
                      balanceData?.purchaseToken !== 0
                        ? balanceData?.purchaseToken
                          ?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "INR",
                          })
                          .split("₹")[1]
                          .split(".")[0]
                        : "0.00"
                    }
                    endClass={"private-card-content-end"}
                    path={"/transactions"}
                  />
                  <PrivateSaleWalletCard
                    className="private-card private-card-3"
                    Label={"Airdrop & Referrals"}
                    data={roundData ? roundData.refferalReward + roundData?.bonus +  roundData.welcomeReward === 0 ? "0.00" : roundData.refferalReward + roundData?.bonus + roundData.welcomeReward  : "0.00" }
                    endClass={"private-card-content-end"}
                    path={"/refferals"}
                  />
                  <PrivateSaleWalletCard
                    className="private-card private-card-4"
                    Label={"Withdrawn"}
                    data={
                      userData?.withdraw !== 0
                        ? userData?.withdraw
                          ?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "INR",
                          })
                          .split("₹")[1]
                          .split(".")[0]
                        : "0.00"
                    }
                    endClass={"private-card-content-end"}
                    path={"/transactions"}
                  />
                </Index.Box>
                <Index.Box className="private-sale-wallet-row">
                  {roundList?.map((data) => {
                    return (
                      <Index.Box className="private-card-wallet-col">
                        {dayjs(data?.endDate).diff(dayjs(), "h") < 0 ? (
                          <Index.PrivateSaleWallet
                            walletData={data}
                            buttonName="UNLOCKED"
                            setAvailabaleToWithdraw={setAvailabaleToWithdraw}
                            handleOpenWallet={(roundId) =>
                              handleOpenWallet(roundId)
                            }
                          />
                        ) : (
                          <Index.PrivateSaleWallet
                            walletData={data}
                            buttonName="LOCKED"
                          />
                        )}
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Footer />

      <Index.Modal
        open={open}
        onClose={handleCloseWallet}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="private-wallet-modal modal"
      >
        <Index.Box sx={style} className="private-wallet-inner modal-inner">
          <Index.Typography
            className="private-wallet-modal-title"
            variant="h6"
            component="h2"
          >
            Withdraw
          </Index.Typography>
          <Index.Box className="modal-wallet-flex display-flex">
            <Index.Typography className="modal-wallet-lable">
              Available to Withdraw
            </Index.Typography>
            <Index.Box className="modal-wallet-box">
              <Index.Typography
                className="wallet-box-inner-text"
                variant="h6"
                component="h2"
              >
                {
                  availabaleToWithdraw
                    ?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                    })
                    .split("₹")[1]
                    .split(".")[0]
                }
              </Index.Typography>
              <Index.Typography
                className="wallet-box-inner-text"
                variant="h6"
                component="h2"
              >
                NCXT
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-wallet-flex display-flex">
            <Index.Typography className="modal-wallet-lable">
              Withdraw
            </Index.Typography>
            <Index.TextField
              value={amount}
              type="number"
              className="form-control modal-wallet-box modal-wallet-widthdro-control"
              onChange={onChangeAmount}
              onWheel={(e) => e.target.blur()}
              InputProps={{
                endAdornment: (
                  <Index.InputAdornment className="modal-wallet-widthdro-control-text">
                    NCXT
                  </Index.InputAdornment>
                ),
              }}
            />
          </Index.Box>

          {error && <div className="error-text">{error}</div>}

          <Index.Box className="modal-wallet-flex display-flex">
            <Index.Typography className="modal-wallet-lable">
              Withdrawal Address
            </Index.Typography>
            <Index.Box className="input-box wallet-modal-input-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control wallet-text-control"
                  placeholder="99ag9t34n34tdffs34r232e3gdgi2gdfbd..."
                />
                <span className="wallet-copy-bg">
                  <img src={Index.Svg.copy} />
                </span>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="modal-wallet-flex display-flex">
            <Index.Typography
              className="private-wallet-modal-para private-wallet-modal-para-1"
              variant="h6"
              component="h2"
            >
              Please send{" "}
              {/* <span className="modal-highlight-text">20.00 USDT-TRC20</span>  */}
              to the address above. if you send DEMO to the wrong wallet the
              funds will be Permanently Lost.
            </Index.Typography>
          </Index.Box>
          <Index.Box className="modal-wallet-flex display-flex wallet-box-inner-otp-flex">
            <Index.Typography className="modal-wallet-lable wallet-box-inner-otp-label">
              Enter OTP sent to your email
            </Index.Typography>
            <Index.Box className="input-box wallet-otp-input-box">
              <Index.Box className="form-group">
                <Index.TextField
                  value={OTP}
                  fullWidth
                  type="number"
                  id="fullWidth"
                  className="form-control wallet-text-control"
                  placeholder="OTP"
                  onChange={onOtpChange}
                />
              </Index.Box>
              {otpError && <div className="error-text">{otpError}</div>}
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-wallet-flex display-flex wallet-otp-flex">
            <Index.Typography
              className="private-wallet-modal-para modal-otp-para"
              variant="h6"
              component="h2"
            >
              If you have not received Email OTP check your Spam or trash folder
              or request another OTP in{" "}
              <span className="modal-black-highlight-text">
                {/* {count} Seconds{" "} */}
                {seconds > 0 ? (
                  <p>{seconds < 10 ? `0${seconds}` : seconds} Seconds</p>
                ) : (
                  <p>Didn't recieve code?</p>
                )}
              </span>
            </Index.Typography>
            {seconds !== 0 ? (
              <Index.PrimaryButton
                className="primary-btn wallet-Request-btn"
                btnLabel="Request OTP"
                onClick={() => sendOTP()}
              />
            ) : (
              <Index.PrimaryButton
                className="primary-btn wallet-Request-btn"
                btnLabel="Resend OTP"
                onClick={() => sendOTP()}
              />
            )}
          </Index.Box>
          <Index.Box className="modal-wallet-flex display-flex wallet-btn-flex">
            <Index.PrimaryBorderButton
              className="primary-border-btn wallet-modal-cancel-btn"
              btnLabel="Cancel"
              onClick={handleCloseWallet}
            />
            <Index.PrimaryButton
              className="primary-btn  wallet-withdrow-btn"
              btnLabel="Withdraw"
              onClick={handleWalletRequest}
            />
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
