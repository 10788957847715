import axios from "axios";


// const API_ENDPOINT = "http://localhost:3015/api/";
const API_ENDPOINT = "http://35.177.56.74:3015/api/";


const DataService = axios.create({
baseURL: API_ENDPOINT,
  // headers: {
  //   auth:
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjMzYThjM2IzZWQyMmQzYWQzYmY4OTQ3In0sImlhdCI6MTY3Mjk5NTEwMX0.OA8QSPqTf392r4uIJxI44_2v8SAFvFKB4rSPH9GdHvY",
  // },
});

export { DataService, API_ENDPOINT };
