import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import moment from "moment";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";
import { getGeneralSettingsFromRedux } from "../../../../redux/features/slices/Admin/AdminService";
const Accordion = styled((props) => (
  <Index.MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <Index.MuiAccordionSummary
    expandIcon={<Index.ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionDetails = styled(Index.MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const RefferalProgram = () => {
  const data = useSelector((store) => store.admin.userData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [referalData, setReferalData] = useState([]);
  const [expanded, setExpanded] = useState("");
  const [expandedTransaction, setExpandedTransaction] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  const generalSettings = useSelector(state => state.admin.generalSettings);


  const handleChange = (panel, table) => (event, newExpanded) => {
    if (table === "Tr") {
      setExpandedTransaction(newExpanded ? panel : false);
    }
    setExpanded(newExpanded ? panel : false);
  };

  const getReferalUsers = async () => {
    await DataService.get(API.User.getReferalDashboardUsers, {
      headers: {
        auth: data.token,
      },
    }).then((response) => {
      setReferalData(response?.data?.data);
    }).catch((error) => {
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/")
      }
    });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const copyToClipBoard = async () => {
    let url = `http://presale-ncx.appworkdemo.com/sign-up?search=${data.referCode}`;
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) { }
  };

  useEffect(() => {
    getReferalUsers();

    dispatch(getGeneralSettingsFromRedux())

  }, []);
  return (

    <Index.Box className="dashboard-referral-col">
      <Index.Box className="dashboard-referral-flex">
        <Index.Typography
          className="dash-referral-text"
          component="h3"
          variant="h3"
        >
          Referral <span className="dash-referral-span-text">Program</span>
        </Index.Typography>
        <Index.Typography
          className="dash-referral-para"
          component="h3"
          variant="h3"
        >
          Earn {generalSettings.refferalTokenPerRefferal}  NCXT for each successful Referral
        </Index.Typography>
      </Index.Box>
      <Index.Box className="desktop-view">
        <Index.Box className="dash-referral-btn-main display-flex">
          <Index.Box className="text-copy-box dash-text-copy-box">
            <Index.Typography
              className="text-copy"
              component="h3"
              variant="h3"
            >
              http://presale-ncx.appworkdemo.com/sign-up?search=
              {data?.referCode}
            </Index.Typography>
          </Index.Box>
          <CopyToClipboard
            text={`http://presale-ncx.appworkdemo.com/sign-up?search=${data?.referCode}`}
            onCopy={copyToClipBoard}
          >
            <Index.PrimaryButton
              className="primary-btn copy-btn"
              btnLabel={isCopied ? "Copied!" : "Copy"}
              onClick={() => {
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 1500);
              }}
            />
          </CopyToClipboard>
          <Index.Link to={"/refferals"} className="text-decoration">
            <Index.PrimaryBorderButton
              className="primary-border-btn history-btn"
              btnLabel="history"
            />
          </Index.Link>
        </Index.Box>
      </Index.Box>

      <Index.Box className="mobile-view">
        <Index.Box className="transaction-main dash-refferal-main">
          <Index.Box className="trans-head-row dash-referral-mobile-row">
            <Index.Box className="referral-btn-main display-flex">
              <Index.Box className="text-copy-box dash-text-copy-box referral-text-copy-box">
                <Index.Typography
                  className="text-copy"
                  component="h3"
                  variant="h3"
                >
                  https://ncx.cx/register?referralcode=
                  {data?.referCode}
                </Index.Typography>
              </Index.Box>
              <CopyToClipboard
                text={`http://presale-ncx.appworkdemo.com/sign-up?search=${data?.referCode}`}
                onCopy={copyToClipBoard}
              >
                <Index.PrimaryButton
                  className="primary-btn referral-copy-btn referral-copy-mobile-btn"
                  btnLabel={isCopied ? "Copied!" : "Copy"}
                  onClick={() => {
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 1500);
                  }}
                />
              </CopyToClipboard>
            </Index.Box>
            <Index.Box className="dash-history-mobile-btn">
              <Index.Link to={"/refferals"} className="text-decoration">
                <Index.PrimaryBorderButton
                  className="primary-border-btn history-btn"
                  btnLabel="history"
                />
              </Index.Link>
            </Index.Box>
          </Index.Box>

          <Index.Box className="trans-data-main">
            <Index.Box className="trans-data-head-row">
              <Index.Typography
                className="trans-col-title mob-trans-data-col-1"
                component="h3"
                variant="h3"
              >
                Name
              </Index.Typography>
              <Index.Typography
                className="trans-col-title mob-trans-data-col-2"
                component="h3"
                variant="h3"
              >
                TOKENS
              </Index.Typography>

              <Index.Typography
                className="trans-col-title mob-trans-data-col-3"
                component="h3"
                variant="h3"
              >
                STATUS
              </Index.Typography>
            </Index.Box>
            <Index.Box className="cuts-accordian-main">
              {referalData?.length > 0
                ? referalData?.map((transactionData, index) => {
                  return (
                    <Accordion
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      className="cuts-accordian"
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        className="cuts-accordian-summary"
                      >
                        <Index.Box className="trans-data-inner-row">
                          <Index.Box className="trans-data-inner mob-trans-data-col-1">
                            <Index.Box className="trans-inner-data-flex">
                              <Index.Box className="trans-icon-box yellow-icon-box">
                                <img
                                  src={Index.Svg.yellowwatch}
                                  className="transaction-icon"
                                />
                              </Index.Box>
                              <Index.Box className="trans-data-col-flex">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                {(transactionData && transactionData.userName) ? `${transactionData.userName}` : transactionData.processedBy.adminName }
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  {moment(
                                    transactionData?.createdAt
                                  ).format("DD MMM, YYYY h:mm A")}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="trans-data-inner mob-trans-data-col-2">
                            <Index.Typography
                              className="dash-trans-titles"
                              component="h3"
                              variant="h3"
                            >
                              {transactionData?.refReward ?
                                transactionData?.refReward !== 0
                                ? transactionData?.refReward
                                  ?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                  })
                                  .split("₹")[1]
                                  .split(".")[0]
                                : "0.00" : transactionData.token}
                            </Index.Typography>
                            <Index.Typography
                              className="dash-trans-lables"
                              component="h3"
                              variant="h3"
                            >
                              USDT
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="trans-data-inner mob-trans-data-col-3">
                            <Index.Box className="trans-data-flex trans-data-flex-end">
                              <Index.Box className="dots-drop-main">
                                <Index.Button className="dots-drop-btn">
                                  <img
                                    src={Index.Svg.threedots}
                                    className="thre-dots-icon"
                                    alt="logo"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </AccordionSummary>
                      <AccordionDetails className="cuts-accordian-details">
                        <Index.Box className="trans-data-wrapper">
                          <Index.Box className="trans-data-wrapper-inner">
                            <Index.Box className="trans-collpase-row">
                              <Index.Typography
                                className="trans-col-title"
                                component="h3"
                                variant="h3"
                              >
                                Name
                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-titles"
                                component="h3"
                                variant="h3"
                              >
                                {(transactionData && transactionData.userName) ? `${transactionData.userName}` : transactionData.processedBy.adminName }
                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-lables"
                                component="h3"
                                variant="h3"
                              >
                                {moment(transactionData?.createdAt).format(
                                  "DD MMM, YYYY h:mm A"
                                )}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="trans-collpase-row">
                              <Index.Typography
                                className="trans-col-title"
                                component="h3"
                                variant="h3"
                              >
                                Email ID
                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-titles"
                                component="h3"
                                variant="h3"
                              >
                                {(transactionData && transactionData.email) ? `${transactionData.email}` : transactionData.processedBy.email }
                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-lables"
                                component="h3"
                                variant="h3"
                              >
                                {moment(transactionData?.createdAt).format(
                                  "DD MMM, YYYY h:mm A"
                                )}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="trans-collpase-row">
                              <Index.Typography
                                className="trans-col-title"
                                component="h3"
                                variant="h3"
                              >
                                Country
                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-titles"
                                component="h3"
                                variant="h3"
                              >
                                {transactionData?.countryId?.countryName}
                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-lables"
                                component="h3"
                                variant="h3"
                              >
                                {moment(transactionData?.createdAt).format(
                                  "DD MMM, YYYY h:mm A"
                                )}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="trans-collpase-row">
                              <Index.Typography
                                className="trans-col-title"
                                component="h3"
                                variant="h3"
                              >
                                TOKENS
                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-titles"
                                component="h3"
                                variant="h3"
                              >
                                {transactionData?.refReward ?
                                  transactionData?.refReward !== 0
                                  ? transactionData?.refReward
                                    ?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                    })
                                    .split("₹")[1]
                                    .split(".")[0]
                                  : "0.00" : transactionData.token}
                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-lables"
                                component="h3"
                                variant="h3"
                              >
                                {moment(transactionData?.createdAt).format(
                                  "DD MMM, YYYY h:mm A"
                                )}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="yellow-border-btn-main trans-yellow-btn-main">
                            {transactionData?.refReward !== 0 ? (
                              <Index.Box className="yellow-border-btn-main trans-data-btn-main">
                                <Index.Button className="yellow-border-btn trans-data-btn trans-green-btn">
                                  Purchased
                                </Index.Button>
                              </Index.Box>
                            ) : (
                              <Index.Box className="yellow-border-btn-main">
                                <Index.Button className="yellow-border-btn referral-data-btn">
                                  Pending Purchase
                                </Index.Button>
                              </Index.Box>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </AccordionDetails>

                    </Accordion>
                  );
                })
                : "No Data Found"}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="desktop-view">
        <Index.Box className="referral-data-main-dash">
          <Index.Box className="referral-data-head-row">
            <Index.Typography
              className="referral-col-title referral-data-col-1"
              component="h3"
              variant="h3"
            >
              NAME
            </Index.Typography>
            <Index.Typography
              className="referral-col-title referral-data-col-2"
              component="h3"
              variant="h3"
            >
              EMAIL ID
            </Index.Typography>
            <Index.Typography
              className="referral-col-title referral-data-col-3"
              component="h3"
              variant="h3"
            >
              TOKENS EARNED
            </Index.Typography>
            <Index.Typography
              className="referral-col-title referral-data-col-4"
              component="h3"
              variant="h3"
            >
              STATUS
            </Index.Typography>
          </Index.Box>

          {referalData?.length
            ? referalData?.map((data) => {
              return (
                <Index.Box className="trans-data-inner-row">
                  <Index.Box className="trans-data-inner referral-data-col-1">
                    <Index.Typography
                      className="dash-trans-titles"
                      component="h3"
                      variant="h3"
                    >
                                {(data && data.userName) ? `${data.userName}` : data.processedBy.adminName }
                    </Index.Typography>
                    <Index.Typography
                      className="dash-trans-lables"
                      component="h3"
                      variant="h3"
                    >
                      {moment(data?.createdAt).format(
                        "DD MMM, YYYY h:mm A"
                      )}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="trans-data-inner referral-data-col-2">
                    <Index.Typography
                      className="dash-trans-titles"
                      component="h3"
                      variant="h3"
                    >
                                {(data && data.email) ? `${data.email}` : data.processedBy.email }
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="trans-data-inner referral-data-col-3">
                    <Index.Typography
                      className="dash-trans-titles"
                      component="h3"
                      variant="h3"
                    >
                      {data?.refReward && data?.refReward !== 0
                        ? data?.refReward
                          ?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "INR",
                          })
                          .split("₹")[1]
                          .split(".")[0]
                        : data.token}
                    </Index.Typography>
                    <Index.Typography
                      className="dash-trans-lables"
                      component="h3"
                      variant="h3"
                    >
                      NCXT
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="trans-data-inner referral-data-col-4">
                    <Index.Box className="trans-data-flex">
                      {data?.refReward !== 0 ? (
                        <Index.Box className="yellow-border-btn-main trans-data-btn-main">
                          <Index.Button className="yellow-border-btn trans-data-btn trans-green-btn">
                            Purchased
                          </Index.Button>
                        </Index.Box>
                      ) : (
                        <Index.Box className="yellow-border-btn-main">
                          <Index.Button className="yellow-border-btn referral-data-btn">
                            Pending Purchase
                          </Index.Button>
                        </Index.Box>
                      )}

                      <Index.Box className="dots-drop-main">
                        <Index.Button
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          className="dots-drop-btn"
                        >
                          <img
                            src={Index.Svg.threedots}
                            className="thre-dots-icon"
                            alt="logo"
                          />
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              );
            })
            : "No Data found"}
        </Index.Box>
      </Index.Box>
    </Index.Box>

  );
};

export default RefferalProgram;
