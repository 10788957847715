import React from "react";
import Index from "../../../container/Index";
import { Link, useLocation } from "react-router-dom";

export default function UserSidebar() {
  const location = useLocation();
  return (
    <>
      <Index.Box className="user-sidebar-main">
        <Index.Box className="user-sidebar">
          <Index.List className="user-sidebar-ul">
            <Index.ListItem className="user-sidebar-li">
              <Link to="/" className="user-sidebar-link">
                <span className="user-sidebar-dark-text">
                DEMO Token
                  <br />
                  Dashboard
                  <br />
                </span>
              </Link>
            </Index.ListItem>
            <Index.ListItem className="user-sidebar-li">
              <Link
                to="/dashboard"
                className={`user-sidebar-link ${
                  location.pathname === "/dashboard" ? "purple-color" : ""
                }`}
              >
                Dashboard
              </Link>
            </Index.ListItem>
            <Index.ListItem className="user-sidebar-li">
              <Link
                to="/private-sale"
                className={`user-sidebar-link ${
                  location.pathname === "/private-sale"
                    ? "purple-color"
                    : ""
                }`}
              >
                Private Sale
              </Link>
            </Index.ListItem>
            <Index.ListItem className="user-sidebar-li">
              <Link
                to="/wallets"
                className={`user-sidebar-link ${
                  location.pathname === "/wallets"
                    ? "purple-color"
                    : ""
                }`}
              >
                Wallet
              </Link>
            </Index.ListItem>
            <Index.ListItem className="user-sidebar-li">
              <Link
                to="/transactions"
                className={`user-sidebar-link ${
                  location.pathname === "/transactions"
                    ? "purple-color"
                    : ""
                }`}
              >
                Purchase History
              </Link>
            </Index.ListItem>
            <Index.ListItem className="user-sidebar-li">
              <Link
                to="/refferals"
                className={`user-sidebar-link ${
                  location.pathname === "/refferals" ? "purple-color" : ""
                }`}
              >
                Referrals
              </Link>
            </Index.ListItem>
            <Index.ListItem className="user-sidebar-li">
              <Link
                to="/profile"
                className={`user-sidebar-link ${
                  location.pathname === "/profile" ? "purple-color" : ""
                }`}
              >
                Account
              </Link>
            </Index.ListItem>
          </Index.List>
        </Index.Box>

        <Index.Box className="user-need-sidebar">
          <Index.List className="user-need-sidebar-ul">
            <Index.ListItem className="user-need-sidebar-li">
              <Link to="/" className="user-need-sidebar-link sidebar-hightlight-text">
                Need help?
              </Link>
            </Index.ListItem>
            <Index.ListItem className="user-need-sidebar-li">
              <Link to="https://my.ncx.cx/en/helpdesk/chat" className="user-need-sidebar-link primary-color">
                Contact Support
              </Link>
            </Index.ListItem>
            <Index.ListItem className="user-need-sidebar-li">
              <a
                href="https://ncx.cx/user-agreement"
                className="user-need-sidebar-link"
              >
                User Agreement
              </a>
            </Index.ListItem>
            <Index.ListItem className="user-need-sidebar-li">
              <a
                href="https://ncx.cx/kyc-aml-policy"
                className="user-need-sidebar-link"
              >
                AML & KYC Policy
              </a>
            </Index.ListItem>
            <Index.ListItem className="user-need-sidebar-li">
              <a
                href="https://ncx.cx/privacy-policy"
                className="user-need-sidebar-link"
              >
                Privacy Policy
              </a>
            </Index.ListItem>
            <Index.ListItem className="user-need-sidebar-li">
              <a
                href="https://ncx.cx/api-agreement"
                className="user-need-sidebar-link"
              >
                API Documentation
              </a>
            </Index.ListItem>
            <Index.ListItem className="user-need-sidebar-li">
              <a
                href="https://ncx.cx/cookie-policy"
                className="user-need-sidebar-link"
              >
                Cookie Policy
              </a>
            </Index.ListItem>
          </Index.List>
        </Index.Box>
      </Index.Box>
    </>
  );
}
