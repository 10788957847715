import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";


export default function Referrals() {
  const token = useSelector((store) => store.admin.token);
  const [isFetching, setIsFetching] = useState(false);
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = async () => {
    setIsFetching(true)
    await DataService.post(
      API.Admin.REFFERAL,{refferalId: location?.state?.id},
      {
        headers:{
          auth:token
        }
      }
    )
      .then((res) => {
        setIsFetching(false)
        const rows = res.data.data.map((item) => {
         

        let re =   checklevel(item.kycOnNcx)
          return {
            name: item.userName,
            id: item._id,
            email: item.email,
            countryId: item?.countryId?.countryName,
            kyconNcx: re,
            Register: item.registeredOnDEMO === true ? "Yes" : "No",
            twoFactor: item.twoFactor === true ? "Yes" : "No",
            countryIds: item?.countryId?._id,
          };
        });
        setUsers(rows);
        
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };
  const checklevel = (item) => {
    let res = ''


    if(item.Level1 === true){
      res = 'Level 1'
      return res ;
    }else if(item.Level2 === true){
      res = 'Level 2'
      return res ;
    }else{
      res = '-';
      return res ;
    }
  }
  const columns = [
    {
      field: "name",
      headerName: "User Name",
      width: "170",
    },
    {
      field: "email",
      headerName: "Email",
      width: "220",
    },
    {
      field: "countryId",
      headerName: "country",
      width: "140",
    },
    {
      field: "purchasedOnNcx",
      headerName: "Purchased On Demo",
      width: "150",
    },
    {
      field: "Register",
      headerName: "Register On Demo",
      width: "180",
    },
    {
      field: "twoFactor",
      headerName: "2FA",
      width: "80",
    },
  
  ];

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/admin/login"></Navigate>;
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="user-head-title">
                <ToastContainer />
              </Index.Box>
              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                 Referrals
                </Index.Typography>
              </Index.Box>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ height: 530, width: "100%" }}>
                  <Index.DataGrid
                    className="user-data-head"
                    rows={users}
                    columns={columns}
                    pageSize={5}
                    getRowId={(row) => row?.id}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={isFetching}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
