import React, { useEffect } from "react";
import Index from "../../../../component/Index";
import { useState } from "react";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CustomModal from "../../../../component/Modal/CustomModal";
import { ToastContainer, toast } from "react-toastify";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const PendingPurchase = () => {
  const token = useSelector((store) => store.admin.token);
  const adminType = useSelector((store) => store.admin.adminType);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editId, setEditId] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [pendingDepositeUsers, setPendingDepositeUsers] = useState([]);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [hideshowAccept, sethideShowAccept] = useState(false);
  const [hideshowReject, sethideShowReject] = useState(false);
  const [status, setStatus] = useState("");
  const [ncxtCredited, setNcxtCredited] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const open1 = Boolean(anchorEl);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onOpenModal2 = () => {
    setModalOpen2(true);
  };
  const onCloseModal2 = () => {
    setModalOpen2(false);
  };
  const onStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const onOpenModal = () => {
    setModalOpen(true);
  };
  const checklevel = (item) => {
    let res = "";

    if (item === 0) {
      res = "Pay With USDT";
      return res;
    } else if (item === 1) {
      res = "Pay with any Tokens";
      return res;
    } else {
      res = "-";
      return res;
    }
  };
  const checkdata = (item) => {
    if(item){
      return item ;
    }else{
      return '-'
    }
  }
  const onAccept = async (id) => {
  
    setIsFetching(true);
    const formData = new URLSearchParams();
    formData.append("requestId", id);
    formData.append("status", status);
    formData.append("tranxIdOnNcx", transactionId);
    formData.append("receivedToken", ncxtCredited);

    await DataService.post(API.Admin.ACCEPT_DEPOSITE, formData, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        if (status === "approved") {
          toast("Deposite accepted");
        } else {
          toast("Deposite rejected");
        }

        fetchPendingData();
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };

 

  const fetchPendingData = async () => {
    setIsFetching(true);
    await DataService.get(API.Admin.PENDING_DEPOSITE, {
      headers: {
        auth: token,
      },
    })
      .then((res) => {
        const rows = res.data.data.map((item) => {
          let re = checklevel(item?.paymentType);
          return {
            tranxId: checkdata(item?.tranxId),
            userName: checkdata(item?.UserId?.userName),
            clientId: checkdata(item?.UserId?.ncxUserId),
            email: checkdata(item?.UserId?.email),
            roundId: checkdata(item?.roundId?.phaseName),
            usdtAmount: checkdata(item?.requestUsdt),
            requestedToken: checkdata(item?.requestToken),
            bonus: checkdata(item?.bonusToken),
            paymentType: re,
            total: checkdata(item?.totalToken),
            status: checkdata(item?.status),
            tranxIdOnNcx: checkdata(item?.tranxIdOnNcx),
            usdtTaken: checkdata(item?.requestUsdt),
            proceedBy: checkdata(item?.processedBy?.adminName),
            updatedAt: checkdata(item?.updatedAt),
            createdAt: checkdata(item?.createdAt),
            id: item?._id,
          };
        });
        setIsFetching(false);
        setPendingDepositeUsers(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };

  const pendingDepositeColumns = [
    {
      field: "tranxId",
      headerName: "TranxId",
      width: "120",
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: "150",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "clientId",
      headerName: "Client ID",
      width: "150",
    },
    {
      field: "userName",
      headerName: "Name Surname",
      width: "150",
    },
    {
      field: "email",
      headerName: "Email",
      width: "150",
    },
    {
      field: "roundId",
      headerName: "Round Name",
      width: "150",
    },
    {
      field: "usdtAmount",
      headerName: "USDT Amount",
      width: "100",
    },
    {
      field: "requestedToken",
      headerName: "Requested Token",
      width: "100",
    },
    {
      field: "bonus",
      headerName: "Bonus",
      width: "100",
    },
    {
      field: "paymentType",
      headerName: "Payment Type",
      width: "100",
    },
    {
      field: "total",
      headerName: "Total",
      width: "100",
    },
    {
      field: "status",
      headerName: "Status",
      width: "150",
    },

    {
      field: "usdtTaken",
      headerName: "USDT Taken",
      width: "150",
    },
    {
      field: "updatedAt",
      headerName: "Processed Date",
      width: "150",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "actions",
      headerName: "Action",
      width: "70",
      renderCell: (params) => {
        return (
          <>
            <Index.IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleClick1(event);
                setEditId(params?.id);
                onOpenModal2();
                sethideShowAccept(false);
                sethideShowReject(false);
              }}
            >
              <Index.MoreVertIcon />
            </Index.IconButton>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    fetchPendingData();
  }, []);

  return (
    <div>
      <CustomModal
        modalOpen={modalOpen2}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal2}
      >
        <Index.Box className="">
          <Index.Box className="transcation-popup">
            <Index.TextField
              size="small"
              variant="standard"
              value={transactionId}
              onChange={(e) => {
                setTransactionId(e.target.value);
              }}
              placeholder="Enter Transcation Id"
            />
          </Index.Box>
          <Index.Box className="transcation-popup">
            <Index.TextField
              size="small"
              variant="standard"
              value={ncxtCredited}
              onChange={(e) => {
                setNcxtCredited(e.target.value);
              }}
              placeholder="Enter DEMO Credited"
            />
          </Index.Box>
        </Index.Box>
        <Index.DialogActions>
          {adminType == 0 && (
            <>
              <Index.Button
                variant="contained"
                value={status}
                onChange={onStatusChange}
                onClick={() => {
                
                  setStatus("approved");
                  sethideShowAccept(true);
                  sethideShowReject(false);
                }}
              >
                Accept
              </Index.Button>
            </>
          )}
          {adminType == 0 && (
            <>
              <Index.Button
                value={status}
                variant="contained"
                onChange={onStatusChange}
                onClick={() => {
                  setStatus("rejected");
                  sethideShowReject(true);
                  sethideShowAccept(false);
                }}
              >
                Reject
              </Index.Button>
            </>
          )}
        </Index.DialogActions>
        {hideshowAccept && (
          <>
            <Index.Box>
              <Index.DialogTitle
                style={{ fontFamily: "Poppins-Medium", fontSize: 17 }}
              >
                Are you sure want to Accept?
              </Index.DialogTitle>
              <Index.DialogActions>
                <Index.Button
                  onClick={() => {
                    onCloseModal2();
                    onAccept(editId);
                  }}
                  style={{
                    color: "white",
                    backgroundColor: "#00235f",
                    fontFamily: "system-ui",
                    fontSize: 13,
                    minwidth: 64,
                    padding: "6px 16px",
                  }}
                >
                  Confirm
                </Index.Button>
                <Index.Button
                  onClick={() => {
                    sethideShowAccept(false);
                  }}
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    fontSize: 12,
                    fontFamily: "system-ui",
                    minwidth: 64,
                    padding: "6px 16px",
                  }}
                >
                  Cancel
                </Index.Button>
              </Index.DialogActions>
            </Index.Box>
          </>
        )}

        {hideshowReject && (
          <>
            <Index.Box>
              <Index.DialogTitle
                style={{ fontFamily: "Poppins-Medium", fontSize: 17 }}
              >
                Are you sure want to Reject?
              </Index.DialogTitle>
              <Index.DialogActions>
                <Index.Button
                  onClick={() => {
                    onCloseModal2();
                    onAccept(editId);
                  }}
                  style={{
                    color: "white",
                    backgroundColor: "#00235f",
                    fontFamily: "system-ui",
                    fontSize: 13,
                    padding: 5,
                  }}
                >
                  Confirm
                </Index.Button>
                <Index.Button
                  onClick={() => {
                    sethideShowReject(false);
                  }}
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    fontSize: 12,
                    fontFamily: "system-ui",
                  }}
                >
                  Cancel
                </Index.Button>
              </Index.DialogActions>
            </Index.Box>
          </>
        )}
      </CustomModal>
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ height: 530, width: "100%", paddingBottom: "20px" }}>
          <Index.DataGrid
            className="user-data-head"
            rows={pendingDepositeUsers}
            columns={pendingDepositeColumns}
            pageSize={10}
            getRowId={(row) => row?.id}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
            loading={isFetching}
          />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default PendingPurchase;
