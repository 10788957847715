import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
  userBalanceData,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const Accordion = styled((props) => (
  <Index.MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <Index.MuiAccordionSummary
    expandIcon={<Index.ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(Index.MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Referral() {
  const data = useSelector((store) => store.admin.userData);
  const token = useSelector((store) => store.admin.userToken);
  const [isCopied, setIsCopied] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [referalData, setReferalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [roundData, setRoundData] = useState([]);
  const [expanded, setExpanded] = useState("");
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const copyToClipBoard = async () => {
    let url = `http://presale-ncx.appworkdemo.com/sign-up?search=${data.referCode}`;
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) { }
  };
  const RoundBalance = async () => {
    await DataService.get(API.User.GET_BALANCE, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        setRoundData(response?.data?.data);
        dispatch(userBalanceData(response?.data?.data));
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };

  const getReferalUsers = async () => {
    await DataService.get(API.User.getReferalDashboardUsers, {
      headers: {
        auth: data.token,
      },
    })
      .then((response) => {
        setReferalData(response?.data?.data);
        setFilteredData(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };

  useEffect(() => {
    getReferalUsers();
    RoundBalance();
  }, []);

  const onHandleSearch = (e) => {
    const filteredGames = referalData?.filter((item) => {
      const topicIncludes = item.userName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
      const second = item.email
        .toLowerCase()
        .includes(e.target.value.toLowerCase());

      return topicIncludes;
    });
    setFilteredData(filteredGames);
    setSearchVal(e.target.value);
  };

  return (
    <>
      <Index.Header />
      <Index.Box className="page-wrapper">
        <Index.Box className="container">
          <Index.Box className="page-inner-main">
            <Index.Box className="page-left-main">
              <Index.UserSidebar />
            </Index.Box>
            <Index.Box className="page-right-main referral-right-main">
              <Index.Box className="referral-card-row">
                <Index.Box className="referral-card referral-card-1">
                  <Index.Box className="referral-card-flex display-flex">
                    <Index.Box className="dash-icon-main">
                      <img
                        src={Index.Svg.ncxlogoicon}
                        className="private-icons"
                      />
                    </Index.Box>
                    <Index.Box className="private-icon-text-main">
                      <Index.Typography
                        className="private-icon-text"
                        component="h3"
                        variant="h3"
                      >
                        Referral Balance
                      </Index.Typography>
                      <Index.Typography
                        className="private-icon-price"
                        component="h3"
                        variant="h3"
                      >
                        {roundData.refferalReward !== 0
                          ? roundData?.refferalReward
                            ?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "INR",
                            })
                            .split("₹")[1]
                            .split(".")[0]
                          : "0.00"}
                        <span className="private-span-text">NCXT</span>
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="referral-card-flex">
                    <Index.Box className="referral-card-inner">
                      <Index.Box className="referral-card-content">
                        <Index.Typography
                          className="referral-price-text"
                          component="h3"
                          variant="h3"
                        >
                          {roundData.refferalReward !== 0
                            ? roundData?.refferalReward
                              ?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                              })
                              .split("₹")[1]
                              .split(".")[0]
                            : "0.00"}
                        </Index.Typography>
                        <Index.Typography
                          className="referral-price-label"
                          component="h3"
                          variant="h3"
                        >
                          LOCKED
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="referral-card-content">
                        <Index.Typography
                          className="referral-price-text"
                          component="h3"
                          variant="h3"
                        >
                          0.00
                        </Index.Typography>
                        <Index.Typography
                          className="referral-price-label"
                          component="h3"
                          variant="h3"
                        >
                          AVAILABLE
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="referral-card-content">
                        <Index.Typography
                          className="referral-price-text"
                          component="h3"
                          variant="h3"
                        >
                          0.00
                        </Index.Typography>
                        <Index.Typography
                          className="referral-price-label"
                          component="h3"
                          variant="h3"
                        >
                          WITHDRAWN
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="desktop-view">
                <Index.Box className="transaction-main referral-data-main">
                  <Index.Box className="trans-head-row referral-head-row">
                    <Index.Typography
                      className="trans-title"
                      variant="h2"
                      component="h2"
                    >
                      Referral Transactions History
                    </Index.Typography>
                    <Index.Box className="referral-btn-main display-flex">
                      <Index.Box className="text-copy-box dash-text-copy-box referral-text-copy-box">
                        <Index.Typography
                          className="text-copy"
                          component="h3"
                          variant="h3"
                        >
                          http://presale-ncx.appworkdemo.com/sign-up?search=
                          {data?.referCode}
                        </Index.Typography>
                      </Index.Box>
                      <CopyToClipboard
                        text={`http://presale-ncx.appworkdemo.com/sign-up?search=${data?.referCode}`}
                        onCopy={copyToClipBoard}
                      >
                        <Index.PrimaryButton
                          className="primary-btn copy-btn"
                          btnLabel={isCopied ? "Copied!" : "Copy"}
                          onClick={() => {
                            setIsCopied(true);
                            setTimeout(() => {
                              setIsCopied(false);
                            }, 1500);
                          }}
                        />
                      </CopyToClipboard>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-search-flex">
                    <Index.Box className="trans-search-box">
                      <Index.Box className="input-box trans-field  referral-field">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            value={searchVal}
                            onChange={onHandleSearch}
                            className="form-control"
                            placeholder="Search by name or email"
                          />
                          <img
                            src={Index.Svg.greysearch}
                            className="search-icon"
                          />
                          <Index.Box className="trans-search-main">
                            <Index.Box className="trans-search-btn-main">
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index.Svg.token1}
                                  className="trans-icons"
                                />
                              </Index.Button>
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index.Svg.token2}
                                  className="trans-icons"
                                />
                              </Index.Button>
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index.Svg.settingicon}
                                  className="trans-icons"
                                />
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-data-main scrollbar">
                    <Index.Box className="trans-data-head-row">
                      <Index.Typography
                        className="trans-col-title referral-data-col-1"
                        component="h3"
                        variant="h3"
                      >
                        NAME
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title referral-data-col-2"
                        component="h3"
                        variant="h3"
                      >
                        EMAIL ID
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title referral-data-col-3"
                        component="h3"
                        variant="h3"
                      >
                        COUNTRY
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title referral-data-col-4"
                        component="h3"
                        variant="h3"
                      >
                        TOKENS
                        <br />
                        EARNED
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title referral-data-col-5"
                        component="h3"
                        variant="h3"
                      >
                        STATUS
                      </Index.Typography>
                    </Index.Box>

                    {filteredData?.length > 0
                      ? filteredData?.map((data) => {
                        return (
                          <Index.Box className="trans-data-inner-row">
                            <Index.Box className="trans-data-inner referral-data-col-1">
                              <Index.Typography
                                className="dash-trans-titles"
                                component="h3"
                                variant="h3"
                              >
                                {(data && data.userName) ? `${data.userName}` : data.processedBy.adminName}

                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-lables"
                                component="h3"
                                variant="h3"
                              >
                                {moment(data?.createdAt).format(
                                  "DD MMM, YYYY h:mm A"
                                )}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="trans-data-inner referral-data-col-2">
                              <Index.Typography
                                className="dash-trans-titles"
                                component="h3"
                                variant="h3"
                              >
                                {(data && data.email) ? `${data.email}` : data.processedBy.email}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="trans-data-inner referral-data-col-3">
                              <Index.Typography
                                className="dash-trans-titles"
                                component="h3"
                                variant="h3"
                              >
                                {data?.countryId?.countryName}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="trans-data-inner referral-data-col-4">
                              <Index.Typography
                                className="dash-trans-titles"
                                component="h3"
                                variant="h3"
                              >
                                {data?.refReward && data?.refReward !== 0
                                  ? data?.refReward
                                    ?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                    })
                                    .split("₹")[1]
                                    .split(".")[0]
                                  : data?.token}
                              </Index.Typography>
                              <Index.Typography
                                className="dash-trans-lables"
                                component="h3"
                                variant="h3"
                              >
                                NCXT
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="trans-data-inner referral-data-col-5">
                              <Index.Box className="trans-data-flex">
                                {data?.refReward !== 0 ? (
                                  <Index.Box className="yellow-border-btn-main trans-data-btn-main">
                                    <Index.Button className="yellow-border-btn trans-data-btn trans-green-btn">
                                      Purchased
                                    </Index.Button>
                                  </Index.Box>
                                ) : (
                                  <Index.Box className="yellow-border-btn-main">
                                    <Index.Button className="yellow-border-btn referral-data-btn">
                                      Pending Purchase
                                    </Index.Button>
                                  </Index.Box>
                                )}

                                <Index.Box className="dots-drop-main">
                                  <Index.Button
                                    id="basic-button"
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClick}
                                    className="dots-drop-btn"
                                  >
                                    <img
                                      src={Index.Svg.threedots}
                                      className="thre-dots-icon"
                                      alt="logo"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        );
                      })
                      : "No Data found"}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="mobile-view">
                <Index.Box className="transaction-main">
                  <Index.Box className="trans-head-row trans-head-mobile-row">
                    <Index.Typography
                      className="trans-title referral-title"
                      variant="h2"
                      component="h2"
                    >
                      Referral Transactions History
                    </Index.Typography>
                    <Index.Box className="referral-btn-main display-flex">
                      <Index.Box className="text-copy-box dash-text-copy-box referral-text-copy-box">
                        <Index.Typography
                          className="text-copy"
                          component="h3"
                          variant="h3"
                        >
                          http://presale-ncx.appworkdemo.com/sign-up?search=
                          {data?.referCode}
                        </Index.Typography>
                      </Index.Box>
                      <CopyToClipboard
                        text={`http://presale-ncx.appworkdemo.com/sign-up?search=${data?.referCode}`}
                        onCopy={copyToClipBoard}
                      >
                        <Index.PrimaryButton
                          className="primary-btn referral-copy-btn referral-copy-mobile-btn"
                          btnLabel={isCopied ? "Copied!" : "Copy"}
                          onClick={() => {
                            setIsCopied(true);
                            setTimeout(() => {
                              setIsCopied(false);
                            }, 1500);
                          }}
                        />
                      </CopyToClipboard>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-search-flex">
                    <Index.Box className="trans-search-box">
                      <Index.Box className="trans-status-flex">
                        <Index.Typography className="trans-status-text trans-status-highlight">
                          Pending
                        </Index.Typography>
                        <Index.Typography className="trans-status-text">
                          Approved
                        </Index.Typography>
                        <Index.Typography className="trans-status-text">
                          Bonuses
                        </Index.Typography>
                        <Index.Typography className="trans-status-text">
                          All
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="input-box trans-field">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            value={searchVal}
                            onChange={onHandleSearch}
                            className="form-control"
                            placeholder="Transaction ID to quick Search"
                          />
                          <img
                            src={Index.Svg.greysearch}
                            className="search-icon"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="mobile-trans-serch-main">
                        <Index.Box className="trans-search-btn-main">
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index.Svg.token1}
                              className="trans-icons"
                            />
                          </Index.Button>
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index.Svg.token2}
                              className="trans-icons"
                            />
                          </Index.Button>
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index.Svg.settingicon}
                              className="trans-icons"
                            />
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-data-main">
                    <Index.Box className="trans-data-head-row">
                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-1"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-2"
                        component="h3"
                        variant="h3"
                      >
                        TOKENS
                      </Index.Typography>

                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-3"
                        component="h3"
                        variant="h3"
                      >
                        STATUS
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="cuts-accordian-main">
                      {filteredData?.length > 0
                        ? filteredData?.map((transactionData, index) => {
                          return (
                            <Accordion
                              expanded={expanded === `panel${index}`}
                              onChange={handleChange(`panel${index}`)}
                              className="cuts-accordian"
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                className="cuts-accordian-summary"
                              >
                                <Index.Box className="trans-data-inner-row">
                                  <Index.Box className="trans-data-inner mob-trans-data-col-1">
                                    <Index.Box className="trans-inner-data-flex">
                                      <Index.Box className="trans-icon-box yellow-icon-box">
                                        <img
                                          src={Index.Svg.yellowwatch}
                                          className="transaction-icon"
                                        />
                                      </Index.Box>
                                      <Index.Box className="trans-data-col-flex">
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {(transactionData && transactionData.userName) ? `${transactionData.userName}` : transactionData.processedBy.adminName}

                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {moment(
                                            transactionData?.createdAt
                                          ).format("DD MMM, YYYY h:mm A")}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box className="trans-data-inner mob-trans-data-col-2">
                                    <Index.Typography
                                      className="dash-trans-titles"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {transactionData?.refReward && transactionData?.refReward !== 0
                                        ? transactionData?.refReward
                                          ?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "INR",
                                          })
                                          .split("₹")[1]
                                          .split(".")[0]
                                        : transactionData?.token}                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-lables"
                                      component="h3"
                                      variant="h3"
                                    >
                                      USDT
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="trans-data-inner mob-trans-data-col-3">
                                    <Index.Box className="trans-data-flex trans-data-flex-end">
                                      <Index.Box className="dots-drop-main">
                                        <Index.Button className="dots-drop-btn">
                                          <img
                                            src={Index.Svg.threedots}
                                            className="thre-dots-icon"
                                            alt="logo"
                                          />
                                        </Index.Button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </AccordionSummary>
                              <AccordionDetails className="cuts-accordian-details">
                                <Index.Box className="trans-data-wrapper">
                                  <Index.Box className="trans-data-wrapper-inner">
                                    <Index.Box className="trans-collpase-row">
                                      <Index.Typography
                                        className="trans-col-title"
                                        component="h3"
                                        variant="h3"
                                      >
                                        Name
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-titles"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {(transactionData && transactionData.userName) ? `${transactionData.userName}` : transactionData.processedBy.adminName}
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-lables"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {moment(
                                          transactionData?.createdAt
                                        ).format("DD MMM, YYYY h:mm A")}
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="trans-collpase-row">
                                      <Index.Typography
                                        className="trans-col-title"
                                        component="h3"
                                        variant="h3"
                                      >
                                        Email ID
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-titles"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {(transactionData && transactionData.email) ? `${transactionData.email}` : transactionData.processedBy.email}
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-lables"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {moment(
                                          transactionData?.createdAt
                                        ).format("DD MMM, YYYY h:mm A")}
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="trans-collpase-row">
                                      <Index.Typography
                                        className="trans-col-title"
                                        component="h3"
                                        variant="h3"
                                      >
                                        Country
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-titles"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {
                                          transactionData?.countryId
                                            ?.countryName
                                        }
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-lables"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {moment(
                                          transactionData?.createdAt
                                        ).format("DD MMM, YYYY h:mm A")}
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="trans-collpase-row">
                                      <Index.Typography
                                        className="trans-col-title"
                                        component="h3"
                                        variant="h3"
                                      >
                                        TOKENS
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-titles"
                                        component="h3"
                                        variant="h3"
                                      >
       {transactionData?.refReward && transactionData?.refReward !== 0
                                        ? transactionData?.refReward
                                          ?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "INR",
                                          })
                                          .split("₹")[1]
                                          .split(".")[0]
                                        : transactionData?.token}                                       </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-lables"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {moment(
                                          transactionData?.createdAt
                                        ).format("DD MMM, YYYY h:mm A")}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box className="yellow-border-btn-main trans-yellow-btn-main">
                                    {transactionData?.refReward !== 0 ? (
                                      <Index.Box className="yellow-border-btn-main trans-data-btn-main">
                                        <Index.Button className="yellow-border-btn trans-data-btn trans-green-btn">
                                          Purchased
                                        </Index.Button>
                                      </Index.Box>
                                    ) : (
                                      <Index.Box className="yellow-border-btn-main">
                                        <Index.Button className="yellow-border-btn referral-data-btn">
                                          Pending Purchase
                                        </Index.Button>
                                      </Index.Box>
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })
                        : "No Data Found"}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="pages-pagination-main">
                  <Index.TablePagination
                    className="pages-pagination"
                    component="div"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Index.Box>
                <Index.Box className="pages-pagination-main">
                  <Index.Box className="pages-pagination-flex">
                    <Index.Typography className="pages-pagination-text">
                      Show
                    </Index.Typography>
                    <Index.Box className="pages-dropdown-main">
                      <Index.Button className="pages-pagination-btn">
                        5
                      </Index.Button>
                    </Index.Box>
                    <Index.Typography className="pages-pagination-text">
                      OF 05
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Footer />
    </>
  );
}
