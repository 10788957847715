import React, { useState } from "react";
import Index from "../../../Index";
import { Link } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import message from "../../../../ValidationMessage.json";
import { styled } from "@mui/material/styles";
import UseCountry from "../../../../UseCountry";

import {
  validateConfirmPassword,
  validateEmail,
  validateName,
  validatePassword,
  validateCountry,
} from "../../../common/AuthValidation";
let getKeys = (data) => {
  return Object.fromEntries(new URLSearchParams(data));
};

export default function SignUp() {
  const countriesData = UseCountry(API.Admin.GET_USER_COUNTRY_LIST);
  const { search } = useLocation();
  const data = useSelector((store) => store.admin.isClaimAirDrop);
  const [emailId, setEmailId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [registerFields, setRegisterFields] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    country: "",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const OnTermsChange = (e) => {
    setTerms(e.target.checked);
    setTermsError("");
  };

  const onHandleInputChange = (event) => {
    const { name, value } = event.target;
    setRegisterFields({
      ...registerFields,
      [name]: value,
    });

    if (name === "confirmPassword") {
      setError(validateConfirmPassword(value === registerFields.password));
    }
    if (name === "name") {
      setNameError(validateName(value));
    }
    if (name === "password") {
      setPasswordError(validatePassword(value));
    }
    if (name === "email") {
      setEmailError(validateEmail(value));
    }
    if (name === "country") {
      setCountryError(validateCountry(value));
    }
  };

  function submitError() {
    if (registerFields.name.length === 0) {
      setNameError(message.ENTER_NAME);
    } else {
      setNameError("");
    }
    if (registerFields.email.length === 0) {
      setEmailError(message.ENTER_EMAIL);
    } else {
      setEmailError("");
    }
    if (registerFields.country.length === 0) {
      setCountryError(message.SELECT_COUNTRY);
    } else {
      setCountryError("");
    }
    if (registerFields.confirmPassword.length === 0) {
      setError(message.ENTER_CONFIRM_PASSWORD);
    } else {
      if (registerFields.password !== registerFields.confirmPassword) {
        setError("Password and confirm password must be same");
      } else {
        setError("");
      }
    }
    if (registerFields.password.length === 0) {
      setPasswordError(message.ENTER_PASSWORD);
    } else {
      setPasswordError("");
    }
    if (terms === false) {
      setTermsError(message.SELECT_TERMS);
    }
  }

  const onCreateAccount = async () => {
    let loginType;
    if (search) {
      loginType = 2;
    } else if (data) {
      loginType = 1;
    } else {
      loginType = 0;
    }
    let samePasswordCond =
      registerFields.password === registerFields.confirmPassword;
    if (
      terms === true &&
      registerFields.name.length > 0 &&
      registerFields.email.length > 0 &&
      registerFields.password.length &&
      registerFields.confirmPassword.length &&
      samePasswordCond && registerFields.country
    ) {
      const formData = new URLSearchParams();
      formData.append("userName", registerFields.name);
      formData.append("email", registerFields.email);
      formData.append("password", registerFields.password);
      formData.append("countryId", registerFields.country);
      formData.append("registerType", loginType);
      if (search) {
        formData.append("referCode", getKeys(search.substring(1)).search);
      }
      await DataService.post(API.User.RegisterUser, formData)
        .then((response) => {
          toast(response.data.message);
          if (search) {
            localStorage.setItem(
              "referCode",
              getKeys(search.substring(1)).search
            );
          } else {
            localStorage.removeItem("referCode");
          }
          localStorage.setItem("User_id", response.data.data._id);
          setEmailId(response.data.data.eVerificationId);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
      setRegisterFields({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        country: "",
      });
    } else {
      submitError();
    }
  };

  // start checkbox

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 0,
    border: "1px solid #E6EFFB",
    width: 16,
    height: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#FFFFFF",
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "transparent",
    "&:before": {
      display: "block",
      width: 12,
      height: 12,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%236E66E6'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "transparent",
    },
  });

  // Inspired by blueprintjs
  function BpCheckbox(props) {
    return (
      <Index.Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }

  return (
    <div>
      <Index.Box className="auth-flex-main">
        <Index.Box className="auth-left">
          <Index.AuthVideo />
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-inner-main">
            <Index.Box className="auth-inner">
              <Index.Box className="auth-logo-main">
                <img
                  src={Index.Png.yourLogoHere}
                  className="auth-logo"
                  alt="auth-logo"
                ></img>
                <Index.Typography className="auth-logo-text">
                  Token
                </Index.Typography>
              </Index.Box>
              <ToastContainer />
              <Index.Typography
                className="auth-title"
                variant="h1"
                component="h1"
              >
                Sign Up
              </Index.Typography>
              <Index.Typography className="auth-para" variant="p" component="p">
                Create New DEMO Account
              </Index.Typography>
              <Index.Box className="input-box auth-field">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    name="name"
                    value={registerFields.name}
                    onChange={onHandleInputChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        onCreateAccount();
                      }
                    }}
                    className="form-control"
                    placeholder="Your Name"
                  />
                </Index.Box>
                <p className="error-text">{nameError}</p>
              </Index.Box>
              <Index.Box className="input-box auth-field">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    name="email"
                    value={registerFields.email}
                    onChange={onHandleInputChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        onCreateAccount();
                      }
                    }}
                    id="fullWidth"
                    className="form-control"
                    placeholder="Your Email"
                  />
                </Index.Box>
                <p className="error-text">{emailError}</p>
              </Index.Box>
                
              <Index.Box className="input-box auth-field">
                <Index.Box className="input-box">
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye form-control"
                      id="outlined-adornment-password"
                      name="password"
                      value={registerFields.password}
                      placeholder="Password"
                      onChange={onHandleInputChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          onCreateAccount();
                        }
                      }}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                    />
                  </Index.Box>
                  <p className="error-text">{passwordError}</p>
                </Index.Box>
              </Index.Box>

              <Index.Box className="input-box auth-field">
                <Index.Box className="input-box auth-field">
                  <Index.Box className="input-box">
                    <Index.Box className="form-group">
                      <Index.OutlinedInput
                        name="confirmPassword"
                        placeholder="Confirm password"
                        className="form-control-eye form-control"
                        value={registerFields.confirmPassword}
                        onChange={onHandleInputChange}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            onCreateAccount();
                          }
                        }}
                        id="outlined-adornment-password"
                        type={showConfirmPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    <p className="error-text">{error}</p>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="input-box auth-field">
                <Index.Box className="input-box auth-field">
                  <Index.Box className="input-box">
                    <Index.Box className="dropdown-box">
                      <Index.FormControl className="form-control">
                        <Index.Select
                          value={registerFields.country}
                          name="country"
                          onChange={onHandleInputChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          className="form-control"
                        >
                          <Index.MenuItem disabled value="">
                            <>Select Country</>
                          </Index.MenuItem>
                          {countriesData.data.map((option) => (
                            <Index.MenuItem key={option._id} value={option._id}>
                              {option.countryName}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                    <p className="error-text">{countryError}</p>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="auth-checkbox-main">
                <Index.FormControlLabel
                  control={
                    <BpCheckbox
                      value={terms}
                      // onChange={(e) => setTerms(e.target.checked)}
                      onChange={OnTermsChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          onCreateAccount();
                        }
                      }}
                    />
                  }
                  className="checkbox-lable"
                />
                <Index.Typography className="auth-checkbox-lable">
                  {" "}
                  I Agree to the{" "}
                  <a
                    href="https://ncx.cx/user-agreement"
                    className="auth-checkbox-spanlable text-decoration"
                  >
                    Terms & Condition
                  </a>{" "}
                  And{" "}
                  <a
                    href="https://ncx.cx/privacy-policy"
                    className="auth-checkbox-spanlable text-decoration"
                  >
                    Privacy and Policy.
                  </a>
                </Index.Typography>
              </Index.Box>
              <p className="error-text">{termsError}</p>

              <Index.PrimaryButton
                className="primary-btn auth-btn"
                type="submit"
                btnLabel="Create Account"
                onClick={onCreateAccount}
              />
              <Index.Typography
                className="auth-sub-para"
                variant="p"
                component="p"
              >
                Already have an account?{" "}
                <span className="auth-sub-para-span">
                  <Link
                    to="/sign-in"
                    className="auth-sub-para-span text-decoration"
                  >
                    Sign In instead
                  </Link>
                </span>{" "}
              </Index.Typography>
              <Index.AuthFooter />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
