import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { useDispatch, useSelector } from "react-redux";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
  userBalanceData,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import DashbordCard from "../../../../component/common/DashbordCard";
import RefferalProgram from "./RefferalProgram";
import RecentTransaction from "./RecentTransaction";
import Privatesale from "./Privatesale";
import { useNavigate } from "react-router-dom";

export default function UserDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roundData, setRoundData] = useState({});

  const currentRoundData = useSelector(
    (store) => store.admin.upcomingCurrentRoundData
  );
  const userBalanceData1 = useSelector((store) => store.admin.userBalanceData);
  const token = useSelector((store) => store.admin.userToken);

  const RoundBalance = async () => {
    await DataService.get(API.User.GET_BALANCE, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        dispatch(userBalanceData(response?.data?.data));
      })
      .catch((error) => {
        if (error?.response?.data?.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };

  useEffect(() => {
    RoundBalance();
  }, []);

  useEffect(() => {
    setRoundData(userBalanceData1);
  }, [userBalanceData1]);
  return (
    <div>
      <Index.Header />

      <Index.Box className="page-wrapper">
        <Index.Box className="container">
          <Index.Box className="page-inner-main">
            <Index.Box className="page-left-main">
              <Index.UserSidebar />
            </Index.Box>
            <Index.Box className="page-right-main dash-page-right-main">
              <Index.Box className="user-dashboard-main">
                <Index.Box className="user-dash-first-row display-flex">
                  <Index.Box className="user-dash-first-row-left">
                    <Index.Box className="dashboard-col">
                      <DashbordCard
                        className="dashboard-card dashboard-card-1"
                        mainLable={"Total Balance"}
                        lableData={
                          roundData?.reward && roundData?.reward !== 0
                            ? roundData?.reward
                              ?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                              })
                              .split("₹")[1]
                              .split(".")[0]
                            : "0.00"
                        }
                        lokedData={
                          roundData?.reward && roundData?.reward !== 0
                            ? roundData?.reward
                              ?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                              })
                              .split("₹")[1]
                              .split(".")[0]
                            : "0.00"
                        }
                        btnLabelPath={"/wallets"}
                        btnLabel={"WALLET"}
                      />

                      {currentRoundData?.current?.length !== 0 ? (
                        <Index.Box className="dash-price-card">
                          <Index.Box className="user-private-sale-final-col">
                            <Index.PriceCard
                              data={currentRoundData?.current[0]}
                            />
                          </Index.Box>
                        </Index.Box>
                      ) : (
                        <Index.Box className="dash-price-card">
                          <Index.Box className="user-private-sale-final-col">
                            <Index.Box className="price-card-main">
                              <Index.Box className="price-card-inner">
                                Coming Soon
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="user-dash-first-row-right">
                    <Index.Box className="user-dash-card-flex display-flex">
                      <DashbordCard
                        className="dashboard-card dashboard-card-2"
                        mainLable={" Airdrop & Referrals"}
                        lableData={
                          `${roundData.refferalReward}` && roundData.welcomeReward
                            ? (
                                roundData?.refferalReward +
                                roundData?.welcomeReward + roundData?.bonus
                              )
                                ?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "INR",
                                })
                                .split("₹")[1]
                                .split(".")[0]
                            : "0.00"
                        }
                        lokedData={
                          `${roundData.refferalReward}` && roundData.welcomeReward
                            ? (
                                roundData?.refferalReward +
                                roundData?.welcomeReward
                              )
                                ?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "INR",
                                })
                                .split("₹")[1]
                                .split(".")[0]
                            : "0.00"
                        }
                        btnLabelPath={"/refferals"}
                        btnLabel={"HISTORY"}
                      />
                      <DashbordCard
                        className="dashboard-card dashboard-card-3"
                        mainLable={"Purchased Token"}
                        lableData={
                          roundData.purchaseToken &&
                            roundData.purchaseToken !== 0
                            ? roundData.purchaseToken
                              ?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                              })
                              .split("₹")[1]
                              .split(".")[0]
                            : "0.00"
                        }
                        lokedData={
                          roundData.purchaseToken &&
                            roundData.purchaseToken !== 0
                            ? roundData.purchaseToken
                              ?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                              })
                              .split("₹")[1]
                              .split(".")[0]
                            : "0.00"
                        }
                        btnLabelPath={"/wallets"}
                        btnLabel={"WALLET"}
                      />
                    </Index.Box>
                    <RefferalProgram />
                  </Index.Box>
                </Index.Box>

                <RecentTransaction />
                <Index.Box className="gradiant-border"></Index.Box>
                <Privatesale />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Footer />
    </div>
  );
}
